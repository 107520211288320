import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {toArrayWithIds} from '../lib/firebase/utils';
import {
  startMessagesListener,
  stopMessagesListener,
  fetchMessages,
  resetMessages,
} from '../lib/reducers/messages';
import {Messages} from '../components/Messages';
import {TextMessageComposer} from '../components/TextMessageComposer';
import {Header} from '../components/Header';
import {BottomAnchoredViewport} from '../components/BottomAnchoredViewport';

class ConversationPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewportHeight: 0,
    };
    this._loadMoreMessages = this._loadMoreMessages.bind(this);
    this._resetMessages = this._resetMessages.bind(this);
    this._startMessagesListener = this._startMessagesListener.bind(this);
    this._stopMessagesListener = this._stopMessagesListener.bind(this);
    this._processUnseenMessages = this._processUnseenMessages.bind(this);
    this._markMessagesAsSeen = this._markMessagesAsSeen.bind(this);
    this._markConversationAsSeen = this._markConversationAsSeen.bind(this);
    this._resizeViewport = this._resizeViewport.bind(this);

    this.headerElement = React.createRef();
    this.textMessageComposerRef = React.createRef();
  }

  componentDidMount() {
    this._startMessagesListener();
    this._processUnseenMessages();

    this._resizeViewport();
    window.addEventListener('resize', this._resizeViewport);
  }

  componentDidUpdate(prevProps) {
    const {match: {params: {accountPhoneNumber: prevAccountPhoneNumber, phoneNumber: prevPhoneNumber}}} = prevProps;
    const {match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    if (phoneNumber !== prevPhoneNumber || accountPhoneNumber !== prevAccountPhoneNumber) {
      this._resetMessages();
      this._loadMoreMessages();
    }
    this._processUnseenMessages();
  }

  componentWillUnmount() {
    clearTimeout(this.seenTimeout);
    this._resetMessages();
    this._stopMessagesListener();
    window.removeEventListener('resize', this._resizeViewport);
  }

  _resizeViewport() {
    const viewportHeight = window.innerHeight - 50 - this.headerElement.current.offsetHeight - this.textMessageComposerRef.current.offsetHeight;
    if (viewportHeight !== this.state.viewportHeight) {
      this.setState({viewportHeight});
    }
  }

  _startMessagesListener() {
    const {dispatch, match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    dispatch(startMessagesListener(accountPhoneNumber, phoneNumber, 25));
  }

  _stopMessagesListener() {
    const {dispatch} = this.props;
    dispatch(stopMessagesListener());
  }

  _processUnseenMessages() {
    clearTimeout(this.seenTimeout);
    this.seenTimeout = setTimeout(() => {
      const messages = toArrayWithIds(this.props.messages).filter(m => m.unseen);
      if (messages.length > 0) {
        this._markMessagesAsSeen(messages);
      } else {
        this._markConversationAsSeen();
      }
    }, 2000);
  }

  async _markMessagesAsSeen(messages) {
    const {match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    const idToken = await this.props.user.getIdToken();
    try {
      messages.forEach(async message => {
        const body = {
          accountPhoneNumber,
          phoneNumber,
          messageId: message.id,
          unseen: 'false',
        };
        await fetch('https://us-central1-cs-cms.cloudfunctions.net/apiSetUnseen/', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          }),
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  async _markConversationAsSeen() {
    const {match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    const idToken = await this.props.user.getIdToken();
    try {
      const body = {
        accountPhoneNumber,
        phoneNumber,
        unseen: 'false',
      };
      await fetch('https://us-central1-cs-cms.cloudfunctions.net/apiSetUnseen/', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        }),
      });
    } catch (e) {
      console.error(e);
    }
  }

  _loadMoreMessages() {
    const {dispatch, match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    dispatch(fetchMessages(accountPhoneNumber, phoneNumber, 25));
  }

  _resetMessages() {
    const {dispatch} = this.props;
    dispatch(resetMessages());
  }

  render() {
    const {messages, match: {params: {accountPhoneNumber, phoneNumber}}} = this.props;
    return (
      <div className="ConversationPanel">
        <div ref={this.headerElement}>
          <Header />
        </div>
        <BottomAnchoredViewport height={this.state.viewportHeight} onCloseToTop={this._loadMoreMessages}>
          <Messages onLoadMoreClick={this._loadMoreMessages} messages={messages} />
        </BottomAnchoredViewport>
        <div  ref={this.textMessageComposerRef}>
          <TextMessageComposer
            accountPhoneNumber={accountPhoneNumber}
            phoneNumber={phoneNumber}
          />
        </div>
      </div>
    );
  }

}

ConversationPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.object,
};

export default connect(state => ({
  messages: state.messages.messages,
  user: state.user.user,
}))(ConversationPanel);

import React, {Component, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Button,
  BookIcon,
  IconButton,
  Dialog,
  Menu,
  MoreIcon,
  PersonIcon,
  Pill,
  PhoneIcon,
  Popover,
  Position,
  Table,
  Text,
  TextInput,
  Tooltip
} from 'evergreen-ui';

import moment from 'moment';

import {placeCall} from '../lib/reducers/call';
import {prettyPhoneNumber} from '../lib/utils';

const UpdateContactDialog = ({isActive, name, phoneNumber, onUpdateContact, onDone}) => {
  const [newName, setNewName] = useState(name);

  return (
    <Dialog
      isShown={isActive}
      title="Update Contact"
      onConfirm={async (close) => {await onUpdateContact(phoneNumber, newName, () => {close(); onDone()});}}
      confirmLabel="Update"
    >
      <TextInput
        name="text-input-name"
        placeholder="Enter contact name ..."
        onChange={e => setNewName(e.target.value)}
        value={newName}
      />
    </Dialog>
  );
}

const InternalListRow = ({compactMode, dispatch, baseUrl, label, accountPhoneNumber, phoneNumber, date, preview, unseenCount, onUpdateContact}) => {
  const [showUpdateContact, setShowUpdateContact] = useState(false);
  const [tickerDate, setTickerDate] = useState(new Date());
  useEffect(() => {
    const ticker = setInterval(() => setTickerDate(new Date()), 60 * 1000);
    return () => clearInterval(ticker);
  });


  return (
    <Table.Row>
      <Table.Cell flexBasis={200} flexShrink={1} flexGrow={1} display="flex" alignItems="center">
        {!compactMode && (
          <Avatar
            // src="https://clients.mindbodyonline.com/studios/StrengthandConditioningOutlet/clients/100000806_large.jpg"
            name={label || '?'}
            marginY={8}
            size={32}
          />
        )}
        <Tooltip content={prettyPhoneNumber(phoneNumber)} showDelay={label ? 1000 : 60000}>
          <Text
            is={Link}
            to={`${baseUrl}/${phoneNumber}`}
            style={{textDecoration: 'none'}}
            size={400}
            fontWeight={unseenCount ? 700 : 500}
            marginLeft={compactMode ? 0 : 16}
          >
            {label || prettyPhoneNumber(phoneNumber)}
          </Text>
        </Tooltip>
      </Table.Cell>
      {!compactMode && (
        <Table.Cell flexBasis={200} flexShrink={1} flexGrow={2}>
          <Tooltip content={moment(date).format('dd, MMM D, YYYY @ h:mm a')} showDelay={1000}>
            <Text size={400} fontWeight={unseenCount ? 700 : 400}>
              {moment(date).fromNow()}
            </Text>
          </Tooltip>
        </Table.Cell>
      )}
      {!compactMode && (
        <Table.Cell width={56} flex="none">
          {
            unseenCount > 0 &&
              <Pill display="inline-flex" margin={8} color="teal" isSolid>
                {unseenCount}
              </Pill>
          }
        </Table.Cell>
      )}
      <Table.TextCell flexBasis={300} flexShrink={1} flexGrow={7}>
        <Text size={400} fontWeight={unseenCount ? 700 : 400}>
          {preview}
        </Text>
      </Table.TextCell>
      <Table.Cell width={48} flex="none">
        <UpdateContactDialog
          isActive={showUpdateContact}
          phoneNumber={phoneNumber}
          name={label}
          onUpdateContact={onUpdateContact}
          onDone={() => setShowUpdateContact(false)}
        />
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            ({close}) => (
              <Menu>
                <Menu.Group>
                  <Menu.Item
                    icon={PhoneIcon}
                    onSelect={() => {
                      close();
                      dispatch(placeCall(accountPhoneNumber, phoneNumber));
                    }}
                  >
                    Place Call
                  </Menu.Item>
                  {false && <Menu.Item icon="chat">Send Message</Menu.Item>}
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item
                    icon={PersonIcon}
                    onSelect={() => {close(); setShowUpdateContact(true) }}
                  >
                    Edit Contact
                  </Menu.Item>
                  <Menu.Item
                    icon={BookIcon}
                    onSelect={() => {
                      close();
                      const match = phoneNumber.match(/\+1(\d{3})(\d{3})(\d{4})/);
                      const path = match ? match.slice(1).join('-') : '';
                      window.open(`https://www.usphonebook.com/${path}`, '_blank');
                    }}
                  >
                    Lookup Number
                  </Menu.Item>
                  { false && <Menu.Item icon="trash">
                      Delete Conversation
                  </Menu.Item>
                  }
                  { false && <Menu.Item icon="blocked-person" intent="danger">
                    Block Contact
                  </Menu.Item>
                  }
                </Menu.Group>
              </Menu>
            )
          }
        >
          <IconButton icon={MoreIcon} height={24} appearance="minimal" />
        </Popover>
      </Table.Cell>
    </Table.Row>
  );
}


InternalListRow.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  unseenCount: PropTypes.number.isRequired,
};

const ListRow = connect()(InternalListRow);

const List = ({compactMode, accountPhoneNumber, onLoadMoreClick, conversations, baseUrl, onUpdateContact}) => (
  <div className="List">
    <Table>
      <Table.Head>
        <Table.TextHeaderCell flexBasis={200} flexShrink={1} flexGrow={1}>
          Contact
        </Table.TextHeaderCell>
        {!compactMode && (
          <Table.TextHeaderCell flexBasis={200} flexShrink={1} flexGrow={2}>
            Last Activity
          </Table.TextHeaderCell>
        )}
        {!compactMode && (
          <Table.TextHeaderCell width={56} flex="none"/>
        )}
        <Table.TextHeaderCell flexBasis={300} flexShrink={1} flexGrow={7}>
          Preview
        </Table.TextHeaderCell>
        <Table.TextHeaderCell  width={48} flex="none" />
      </Table.Head>
      <Table.Body>
        {
          Object.keys(conversations)
            .map(phoneNumber => Object.assign({phoneNumber}, conversations[phoneNumber]))
            .sort((a,b) => b.timestamp - a.timestamp)
            .map(({label, phoneNumber, timestamp, preview, unseenCount}) => (
              <ListRow
                compactMode={compactMode}
                key={phoneNumber}
                label={label}
                baseUrl={baseUrl}
                accountPhoneNumber={accountPhoneNumber}
                phoneNumber={phoneNumber}
                date={new Date(timestamp)}
                preview={preview}
                unseenCount={unseenCount}
                onUpdateContact={onUpdateContact}
              />
            ))
        }
      </Table.Body>
    </Table>
    <Button onClick={onLoadMoreClick}>More</Button>
  </div>
);

List.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  accountPhoneNumber: PropTypes.string.isRequired,
  conversations: PropTypes.object.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  onUpdateContact: PropTypes.func.isRequired,
};

List.defaultProps = {
  conversations: {},
};

export {
  List,
};

import React, {Component} from 'react';
import {
  Button,
  Pane,
  Text,
  Textarea,
  ThemeConsumer
} from 'evergreen-ui';
import {connect} from 'react-redux';

import {gsm7ify, textSpecs} from '../lib/utils';

const TextMessageComposer = connect(({user: {user}}) => ({
  user,
}))(class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendButton: 'Send',
      locked: false,
      content: '',
      contentInfo: null,
    };

    this._handleContentChange = this._handleContentChange.bind(this);
    this._handleSendClick = this._handleSendClick.bind(this);
  }

  _handleContentChange(e) {
    let {target: {value: content}} = e;
    content = gsm7ify(content);
    const contentInfo = textSpecs(content);
    this.setState({content, contentInfo});
  }

  async _handleSendClick() {
    if (!this.state.locked && this.state.content.length > 0) {
      this.setState({locked: true, sendButton: 'Sending ...'});
      const idToken = await this.props.user.getIdToken();
      const body = {
        from: this.props.accountPhoneNumber,
        to: this.props.phoneNumber,
        content: this.state.content,
      };
      try {
        await fetch('https://us-central1-cs-cms.cloudfunctions.net/twilioMessageSend/', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          }),
        });
        this.setState({locked: false, content: '', contentInfo: null, sendButton: 'Send'});
      } catch (e) {
        console.error(e);
        this.setState({locked: false, sendButton: 'Send'});
      }
    }
  }

  render() {
    const {content, locked, sendButton, contentInfo} = this.state;

    return (
      <ThemeConsumer>
        {
          theme => (
            <Pane>
              <Pane display="flex" paddingY={8}>
                <Textarea
                  size={400}
                  value={content}
                  disabled={locked}
                  onChange={this._handleContentChange}
                  marginRight={16}
                  flex={1}
                />
                <Pane>
                  <Button
                    height={32}
                    iconBefore="airplane"
                    disabled={locked || content.trim().length === 0}
                    onClick={this._handleSendClick}
                  >
                    {sendButton}
                  </Button>
                </Pane>
              </Pane>
              {
                contentInfo &&
                  <Text color="muted" marginX={8}>{JSON.stringify(contentInfo)}</Text>
              }
            </Pane>
          )
        }
      </ThemeConsumer>
    );
  }
});

export {
  TextMessageComposer,
};

import {push} from 'connected-react-router';

export const FETCH_REQUEST = 'schmetterling/call/FETCH_REQUEST';
export const FETCH_SUCCESS = 'schmetterling/call/FETCH_SUCCESS';
export const FETCH_FAILURE = 'schmetterling/call/FETCH_FAILURE';

const initialState = {
  ready: true,
  inflight: null,
  error: null,
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {

  case FETCH_REQUEST:
    return {ready: false, inflight: payload.inflight, error: null};
  case FETCH_SUCCESS:
    return initialState;
  case FETCH_FAILURE:
    console.error(payload.error.message);
    return {ready: true, inflight: null, error: payload.error};

  default:
    return state;
  }
};

const fetchRequest = inflight => ({
  type: FETCH_REQUEST,
  payload: {inflight},
});

const fetchSuccess = () => ({
  type: FETCH_SUCCESS,
  payload: {},
});

const fetchFailure = error => ({
  type: FETCH_FAILURE,
  payload: {error},
});

export const placeCall = (accountPhoneNumber, phoneNumber) => async (dispatch, getState) => {
  const {user: {user}, call: {inflight}} = getState();

  if (inflight && inflight.accountPhoneNumber === accountPhoneNumber && inflight.phoneNumber === phoneNumber) {
    return;
  }

  const idToken = await user.getIdToken();
  const body = {
    accountPhoneNumber,
    phoneNumber,
    agentPhoneNumber: user.phoneNumber,
  };
  dispatch(fetchRequest({accountPhoneNumber, phoneNumber}));
  try {
    await fetch('https://us-central1-cs-cms.cloudfunctions.net/twilioVoiceOutboundCall/', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      }),
    });
    dispatch(fetchSuccess());
    dispatch(push(`/a/${accountPhoneNumber}/${phoneNumber}/`));
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};

export default reducer;

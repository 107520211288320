import React from 'react';
import {Pane, Alert} from 'evergreen-ui';

import {Header} from './Header';

const AccountsPanel = () => (
  <div className="ConversationsPanel">
    <Header />
    <Pane>
      <Alert
        appearance="card"
        intent="none"
        title="Select one of the phone numbers from above. ☝️"
        margin={16}
      />
    </Pane>
  </div>
);

export {
  AccountsPanel,
};

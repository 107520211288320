import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// const config = {
//   apiKey: "AIzaSyBnvgkk3PmRqRui-iz54QCB2VxXKJTuxw0",
//   authDomain: "cs-cms.firebaseapp.com",
//   databaseURL: "https://schmetterling.firebaseio.com",
//   projectId: "cs-cms",
//   storageBucket: "cs-cms.appspot.com",
//   messagingSenderId: "77213453776"
// };

const config = {
  apiKey: 'AIzaSyBnvgkk3PmRqRui-iz54QCB2VxXKJTuxw0',
  authDomain: 'cs-cms.firebaseapp.com',
  databaseURL: 'https://cs-cms.firebaseio.com',
  projectId: 'cs-cms',
  storageBucket: 'cs-cms.appspot.com',
  messagingSenderId: '77213453776',
};

firebase.initializeApp(config);

export {firebase};

import {firebase} from '../firebase';

// const FETCH_REQUEST = 'schmetterling/accounts/FETCH_REQUEST';
// const FETCH_SUCCESS = 'schmetterling/accounts/FETCH_SUCCESS';
// const FETCH_FAILURE = 'schmetterling/accounts/FETCH_FAILURE';
export const UPDATE_ACCOUNT = 'schmetterling/accounts/UPDATE_ACCOUNT';
export const REMOVE_ACCOUNT = 'schmetterling/accounts/REMOVE_ACCOUNT';
export const RESET_ACCOUNT = 'schmetterling/accounts/RESET_ACCOUNT';

const initialState = {
  // inflight: null, // {accountPhoneNumber: ...}
  // error: null,
  accounts: {},
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {

  // case FETCH_REQUEST:
  //   return {...state, inflight: payload.inflight, error: null};
  // case FETCH_SUCCESS:
  //   return {...state, inflight: null, accounts: payload.accounts};
  // case FETCH_FAILURE:
  //   return {...state, inflight: null, error: payload.error};

  case UPDATE_ACCOUNT:
    return {...state, accounts: {...state.accounts, ...payload.account}};
  case REMOVE_ACCOUNT:
    const {[payload.phoneNumber]: _, ...newAccounts} = state.accounts;
    return {...state, accounts: newAccounts};

  case RESET_ACCOUNT:
    return initialState;

  default:
    return state;
  }
};

// const fetchRequest = inflight => ({
//   type: FETCH_REQUEST,
//   payload: {inflight},
// });

// const fetchSuccess = (accounts) => ({
//   type: FETCH_SUCCESS,
//   payload: {accounts},
// });

// const fetchFailure = error => ({
//   type: FETCH_FAILURE,
//   payload: {error},
// });

export const updateAccount = account => ({
  type: UPDATE_ACCOUNT,
  payload: {account},
});

export const removeAccount = phoneNumber => ({
  type: REMOVE_ACCOUNT,
  payload: {phoneNumber},
});

export const resetAccounts = () => ({
  type: RESET_ACCOUNT,
});

// export const fetchAccounts = () => (dispatch, getState) => {

//   firebase.database().ref(`/users/${uid}/accounts/`).once('value')
//     .then(snap => {
//       if (snap.exists()) {
//         const firstAccountId = Object.keys(snap.val())[0];
//         return firebase.database().ref(`/accounts/${firstAccountId}/phoneNumbers/`).once('value')
//           .then(phoneSnap => phoneSnap.exists() ? phoneSnap.val() : null);
//       }
//       return {};
//     })
//     .then(list => dispatch(fetchSuccess(list)))
//     .catch(error => dispatch(fetchFailure(error)));
// };

// export const fetchAccounts = (accountPhoneNumber, size) => (dispatch, getState) => {
//   const {accounts, user: {user: {uid}}, accounts: {inflight}} = getState();

//   if (inflight && inflight.uid === uid) {
//     return;
//   }

//   dispatch(fetchRequest({uid}));

//   // firebase.database().ref(`/users/${uid}/accounts/active`).set(true);

// let ref = firebase.database().ref(`/users/${uid}/accounts/`);

//   ref.once('value')
//     .then(snap => {
//       const newAccounts = snap.val();
//       const newTimestampCursor = Object.values(newAccounts).map(n => n.timestamp).sort()[0];
//       dispatch(fetchSuccess({...conversations, ...newAccounts}, newTimestampCursor));
//     })
//     .catch(error => dispatch(fetchFailure(error)));
// };

let listenerRef, childAddedListener, childChangedListener, childRemovedListener;

export const startAccountListener = () => (dispatch, getState) => {
  const {user: {user}} = getState();
  if (!user) {
    return;
  }
  const uid = user.uid;

  firebase.database().ref(`/users/${uid}/accounts/`).once('value')
    .then(snap => {
      if (snap.exists()) {
        const firstAccountId = Object.keys(snap.val())[0];
        listenerRef = firebase.database().ref(`/accounts/${firstAccountId}/phoneNumbers/`).orderByChild('label');
        childAddedListener = listenerRef.on('child_added', (childSnapshot) => {
          dispatch(updateAccount({[childSnapshot.key]: childSnapshot.val()}));
        });

        childChangedListener = listenerRef.on('child_changed', (childSnapshot) => {
          dispatch(updateAccount({[childSnapshot.key]: childSnapshot.val()}));
        });

        childRemovedListener = listenerRef.on('child_removed', (childSnapshot) => {
          dispatch(removeAccount(childSnapshot.key));
        });
        return null;
      }
    });
};

export const stopAccountListener = () => () => {
  if (childAddedListener) {
    listenerRef.off('child_added', childAddedListener);
    childAddedListener = null;
  }
  if (childChangedListener) {
    listenerRef.off('child_changed', childChangedListener);
    childChangedListener = null;
  }
  if (childRemovedListener) {
    listenerRef.off('child_added', childRemovedListener);
    childRemovedListener = null;
  }
  if (listenerRef) {
    listenerRef = null;
  }
};

export default reducer;

import {firebase} from '../firebase';

export const FETCH_REQUEST = 'schmetterling/conversations/FETCH_REQUEST';
export const FETCH_SUCCESS = 'schmetterling/conversations/FETCH_SUCCESS';
export const FETCH_FAILURE = 'schmetterling/conversations/FETCH_FAILURE';
export const UPDATE_CONVERSATION = 'schmetterling/conversations/UPDATE_CONVERSATION';
export const REMOVE_CONVERSATION = 'schmetterling/conversations/REMOVE_CONVERSATION';
export const RESET_CONVERSATION = 'schmetterling/conversations/RESET_CONVERSATION';

const initialState = {
  inflight: null, // {accountPhoneNumber: ...}
  timestampCursor: null,
  conversations: {},
  error: null,
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {

  case FETCH_REQUEST:
    return {...state, inflight: payload.inflight, error: null};
  case FETCH_SUCCESS:
    return {...state, inflight: null, conversations: payload.conversations, timestampCursor: payload.timestampCursor};
  case FETCH_FAILURE:
    return {...state, inflight: null, error: payload.error};

  case UPDATE_CONVERSATION:
    const {timestampCursor} = state;
    const {timestamp} = Object.values(payload.message)[0];
    return {...state, conversations: {...state.conversations, ...payload.message}, timestampCursor: (timestampCursor && timestampCursor < timestamp ? timestampCursor : timestamp)};
  case REMOVE_CONVERSATION:
    const {[payload.phoneNumber]: _, ...newConversations} = state.conversations;
    return {...state, conversations: newConversations};

  case RESET_CONVERSATION:
    return initialState;

  default:
    return state;
  }
};

const fetchRequest = inflight => ({
  type: FETCH_REQUEST,
  payload: {inflight},
});

const fetchSuccess = (conversations, timestampCursor) => ({
  type: FETCH_SUCCESS,
  payload: {conversations, timestampCursor},
});

const fetchFailure = error => ({
  type: FETCH_FAILURE,
  payload: {error},
});

const updateConversation = message => ({
  type: UPDATE_CONVERSATION,
  payload: {message},
});

const removeConversation = phoneNumber => ({
  type: REMOVE_CONVERSATION,
  payload: {phoneNumber},
});

export const resetConversations = () => ({
  type: RESET_CONVERSATION,
});

export const fetchConversations = (accountPhoneNumber, size) => (dispatch, getState) => {
  const {conversations: {conversations, inflight, timestampCursor}} = getState();

  if (inflight && inflight.accountPhoneNumber === accountPhoneNumber) {
    return;
  }

  dispatch(fetchRequest({accountPhoneNumber}));

  let ref = firebase.database().ref(`/list/${accountPhoneNumber}/`)
    .orderByChild('timestamp');

  if (timestampCursor) {
    ref = ref.endAt(timestampCursor, 'timestamp');
  }

  ref = ref.limitToLast(size+1);

  ref.once('value')
    .then(snap => {
      const newConversations = snap.val();
      const newTimestampCursor = Object.values(newConversations).map(n => n.timestamp).sort()[0];
      dispatch(fetchSuccess({...conversations, ...newConversations}, newTimestampCursor));
    })
    .catch(error => dispatch(fetchFailure(error)));
};

let listenerRef, childAddedListener, childChangedListener, childRemovedListener;

export const startConversationListener = (accountPhoneNumber, size) => (dispatch) => {
  listenerRef = firebase.database().ref(`/list/${accountPhoneNumber}/`).orderByChild('timestamp').limitToLast(size);

  childAddedListener = listenerRef.on('child_added', (childSnapshot) => {
    dispatch(updateConversation({[childSnapshot.key]: childSnapshot.val()}));
  });

  childChangedListener = listenerRef.on('child_changed', (childSnapshot) => {
    dispatch(updateConversation({[childSnapshot.key]: childSnapshot.val()}));
  });

  childRemovedListener = listenerRef.on('child_removed', (childSnapshot) => {
    dispatch(removeConversation(childSnapshot.key));
  });
};

export const stopConversationListener = () => () => {
  if (childAddedListener) {
    listenerRef.off('child_added', childAddedListener);
    childAddedListener = null;
  }
  if (childChangedListener) {
    listenerRef.off('child_changed', childChangedListener);
    childChangedListener = null;
  }
  if (childRemovedListener) {
    listenerRef.off('child_added', childRemovedListener);
    childRemovedListener = null;
  }
  if (listenerRef) {
    listenerRef = null;
  }
};

export default reducer;

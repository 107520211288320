const gsm7ify = message => {
  const r = new RegExp(transliterations.map(t => t.char).join('|'));
  return message.replace(r, c => transliterations.find(t => t.char === c).replacement);
};

const textSpecs = message => {
  // TODO move this to message utils library
  const regexp = new RegExp('^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$');

  let isGsmChars = regexp.test(message);
  if (!isGsmChars) {
    message = gsm7ify(message);
    isGsmChars = regexp.test(message);
  }

  const charsForSingleSegment = isGsmChars ? 160 : 70;
  const charsForMultiSegment = isGsmChars ? 153 : 67;

  const isMultiSegment = message.length > charsForSingleSegment;
  const charsPerSegment = isMultiSegment ? charsForMultiSegment : charsForSingleSegment;
  const charsInLastSegment = message.length % charsPerSegment;
  const charsRemaining = charsInLastSegment === 0 ? 0 : charsPerSegment - charsInLastSegment;
  const segmentCount = parseInt(message.length / charsPerSegment) + (charsInLastSegment === 0 ? 0 : 1);

  return {
    length: message.length,
    charsRemaining,
    segmentCount,
    format: isGsmChars ? 'GSM-7' : 'UCS-2',
  };
};

const pluralize = (number, singular, plural) => (
  `${number} ${number === 1 ? singular : plural}`
);

const formatSeconds = seconds => {
  seconds= Number(seconds);
  let result = [];
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);

  if (h > 0) {
    result.push(pluralize(h, 'hour', 'hours'));
  }
  if (h > 0 || m > 0) {
    result.push(pluralize(m, 'minute', 'minutes'));
  }
  result.push(pluralize(s, 'second', 'seconds'));

  return result.join(', ');
};

const prettyPhoneNumber = phoneNumber => {
  let match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  } else {
    return phoneNumber;
  }

}

export {
  formatSeconds,
  gsm7ify,
  pluralize,
  textSpecs,
  prettyPhoneNumber
};

const transliterations = [
  {
    char: '\u00AB',
    label: 'left-pointing double angle quotation mark',
    replacement: '"'
  },
  {
    char: '\u00BB',
    label: 'right-pointing double angle quotation mark',
    replacement: '"'
  },
  {
    char: '\u201C',
    label: 'left double quotation mark',
    replacement: '"'
  },
  {
    char: '\u201D',
    label: 'right double quotation mark',
    replacement: '"'
  },
  {
    char: '\u02BA',
    label: 'modifier letter double prime',
    replacement: '"'
  },
  {
    char: '\u02EE',
    label: 'modifier letter double apostrophe',
    replacement: '"'
  },
  {
    char: '\u201F',
    label: 'double high-reversed-9 quotation mark',
    replacement: '"'
  },
  {
    char: '\u275D',
    label: 'heavy double turned comma quotation mark ornament',
    replacement: '"'
  },
  {
    char: '\u275E',
    label: 'heavy double comma quotation mark ornament',
    replacement: '"'
  },
  {
    char: '\u301D',
    label: 'reversed double prime quotation mark',
    replacement: '"'
  },
  {
    char: '\u301E',
    label: 'double prime quotation mark',
    replacement: '"'
  },
  {
    char: '\uFF02',
    label: 'fullwidth quotation mark',
    replacement: '"'
  },
  {
    char: '\u2018',
    label: 'left single quotation mark',
    replacement: '\''
  },
  {
    char: '\u2019',
    label: 'right single quotation mark',
    replacement: '\''
  },
  {
    char: '\u02BB',
    label: 'modifier letter turned comma',
    replacement: '\''
  },
  {
    char: '\u02C8',
    label: 'modifier letter vertical line',
    replacement: '\''
  },
  {
    char: '\u02BC',
    label: 'modifier letter apostrophe',
    replacement: '\''
  },
  {
    char: '\u02BD',
    label: 'modifier letter reversed comma',
    replacement: '\''
  },
  {
    char: '\u02B9',
    label: 'modifier letter prime',
    replacement: '\''
  },
  {
    char: '\u201B',
    label: 'single high-reversed-9 quotation mark',
    replacement: '\' '
  },
  {
    char: '\uFF07',
    label: 'fullwidth apostrophe',
    replacement: '\''
  },
  {
    char: '\u00B4',
    label: 'acute accent',
    replacement: '\''
  },
  {
    char: '\u02CA',
    label: 'modifier letter acute accent',
    replacement: '\' '
  },
  {
    char: '\u0060',
    label: 'grave accent',
    replacement: '\' '
  },
  {
    char: '\u02CB',
    label: 'modifier letter grave accent',
    replacement: '\' '
  },
  {
    char: '\u275B',
    label: 'heavy single turned comma quotation mark ornament',
    replacement: '\' '
  },
  {
    char: '\u275C',
    label: 'heavy single comma quotation mark ornament',
    replacement: '\' '
  },
  {
    char: '\u0313',
    label: 'combining comma above',
    replacement: '\' '
  },
  {
    char: '\u0314',
    label: 'combining reversed comma above',
    replacement: '\' '
  },
  {
    char: '\uFE10',
    label: 'presentation form for vertical comma',
    replacement: '\' '
  },
  {
    char: '\uFE11',
    label: 'presentation form for vertical ideographic comma',
    replacement: '\' '
  },
  {
    char: '\u00F7',
    label: 'division sign',
    replacement: '/'
  },
  {
    char: '\u00BC',
    label: 'vulgar fraction one quarter',
    replacement: '1/4'
  },
  {
    char: '\u00BD',
    label: 'vulgar fraction one half',
    replacement: '1/2'
  },
  {
    char: '\u00BE',
    label: 'vulgar fraction three quarters',
    replacement: '3/4'
  },
  {
    char: '\u29F8',
    label: 'big solidus',
    replacement: '/'
  },
  {
    char: '\u0337',
    label: 'combining short solidus overlay',
    replacement: '/'
  },
  {
    char: '\u0338',
    label: 'combining long solidus overlay',
    replacement: '/'
  },
  {
    char: '\u2044',
    label: 'fraction slash',
    replacement: '/'
  },
  {
    char: '\u2215',
    label: 'division slash',
    replacement: '/'
  },
  {
    char: '\uFF0F',
    label: 'fullwidth solidus',
    replacement: '/'
  },
  {
    char: '\u29F9',
    label: 'big reverse solidus',
    replacement: '\\'
  },
  {
    char: '\u29F5',
    label: 'reverse solidus operator',
    replacement: '\\'
  },
  {
    char: '\u20E5',
    label: 'combining reverse solidus overlay',
    replacement: '\\'
  },
  {
    char: '\uFE68',
    label: 'small reverse solidus',
    replacement: '\\'
  },
  {
    char: '\uFF3C',
    label: 'fullwidth reverse solidus',
    replacement: '\\'
  },
  {
    char: '\u0332',
    label: 'combining low line',
    replacement: '_'
  },
  {
    char: '\uFF3F',
    label: 'fullwidth low line',
    replacement: '_'
  },
  {
    char: '\u20D2',
    label: 'combining long vertical line overlay',
    replacement: '|'
  },
  {
    char: '\u20D3',
    label: 'combining short vertical line overlay',
    replacement: '|'
  },
  {
    char: '\u2223',
    label: 'divides',
    replacement: '|'
  },
  {
    char: '\uFF5C',
    label: 'fullwidth vertical line',
    replacement: '|'
  },
  {
    char: '\u23B8',
    label: 'left vertical box line',
    replacement: '|'
  },
  {
    char: '\u23B9',
    label: 'right vertical box line',
    replacement: '|'
  },
  {
    char: '\u23D0',
    label: 'vertical line extension',
    replacement: '|'
  },
  {
    char: '\u239C',
    label: 'left parenthesis extension',
    replacement: '|'
  },
  {
    char: '\u239F',
    label: 'right parenthesis extension',
    replacement: '|'
  },
  {
    char: '\u23BC',
    label: 'horizontal scan line-7',
    replacement: '-'
  },
  {
    char: '\u23BD',
    label: 'horizontal scan line-9',
    replacement: '-'
  },
  {
    char: '\u2015',
    label: 'horizontal bar',
    replacement: '-'
  },
  {
    char: '\uFE63',
    label: 'small hyphen-minus',
    replacement: '-'
  },
  {
    char: '\uFF0D',
    label: 'fullwidth hyphen-minus',
    replacement: '-'
  },
  {
    char: '\u2010',
    label: 'hyphen',
    replacement: '-'
  },
  {
    char: '\u2043',
    label: 'hyphen bullet',
    replacement: '-'
  },
  {
    char: '\uFE6B',
    label: 'small commercial at sign',
    replacement: '@'
  },
  {
    char: '\uFF20',
    label: 'fullwidth commercial at sign',
    replacement: '@'
  },
  {
    char: '\uFE69',
    label: 'small dollar sign',
    replacement: '$'
  },
  {
    char: '\uFF04',
    label: 'fullwidth dollar sign',
    replacement: '$'
  },
  {
    char: '\u01C3',
    label: 'latin letter retroflex click',
    replacement: '!'
  },
  {
    char: '\uFE15',
    label: 'presentation form for vertical exclamation mark',
    replacement: '!'
  },
  {
    char: '\uFE57',
    label: 'small exclamation mark',
    replacement: '!'
  },
  {
    char: '\uFF01',
    label: 'fullwidth exclamation mark',
    replacement: '!'
  },
  {
    char: '\uFE5F',
    label: 'small number sign',
    replacement: '#'
  },
  {
    char: '\uFF03',
    label: 'fullwidth number sign',
    replacement: '#'
  },
  {
    char: '\uFE6A',
    label: 'small percent sign',
    replacement: '%'
  },
  {
    char: '\uFF05',
    label: 'fullwidth percent sign',
    replacement: '%'
  },
  {
    char: '\uFE60',
    label: 'small ampersand',
    replacement: '&'
  },
  {
    char: '\uFF06',
    label: 'fullwidth ampersand',
    replacement: '&'
  },
  {
    char: '\u201A',
    label: 'single low-9 quotation mark',
    replacement: ' ,'
  },
  {
    char: '\u0326',
    label: 'combining comma below',
    replacement: ' ,'
  },
  {
    char: '\uFE50',
    label: 'small comma',
    replacement: ' ,'
  },
  {
    char: '\uFE51',
    label: 'small ideographic comma',
    replacement: ' ,'
  },
  {
    char: '\uFF0C',
    label: 'fullwidth comma',
    replacement: ', '
  },
  {
    char: '\uFF64',
    label: 'halfwidth ideographic comma',
    replacement: ', '
  },
  {
    char: '\u2768',
    label: 'medium left parenthesis ornament',
    replacement: ''
  },
  {
    char: '\u276A',
    label: 'medium flattened left parenthesis ornament',
    replacement: ''
  },
  {
    char: '\uFE59',
    label: 'small left parenthesis',
    replacement: ''
  },
  {
    char: '\uFF08',
    label: 'fullwidth left parenthesis',
    replacement: ''
  },
  {
    char: '\u27EE',
    label: 'mathematical left flattened parenthesis',
    replacement: ''
  },
  {
    char: '\u2985',
    label: 'left white parenthesis',
    replacement: ''
  },
  {
    char: '\u2769',
    label: 'medium right parenthesis ornament',
    replacement: ')'
  },
  {
    char: '\u276B',
    label: 'medium flattened right parenthesis ornament',
    replacement: ')'
  },
  {
    char: '\uFE5A',
    label: 'small right parenthesis',
    replacement: ')'
  },
  {
    char: '\uFF09',
    label: 'fullwidth right parenthesis',
    replacement: ')'
  },
  {
    char: '\u27EF',
    label: 'mathematical right flattened parenthesis',
    replacement: ')'
  },
  {
    char: '\u2986',
    label: 'right white parenthesis',
    replacement: ')'
  },
  {
    char: '\u204E',
    label: 'low asterisk',
    replacement: '*'
  },
  {
    char: '\u2217',
    label: 'asterisk operator',
    replacement: '*'
  },
  {
    char: '\u229B',
    label: 'circled asterisk operator',
    replacement: '*'
  },
  {
    char: '\u2722',
    label: 'four teardrop-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u2723',
    label: 'four balloon-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u2724',
    label: 'heavy four balloon-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u2725',
    label: 'four club-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u2731',
    label: 'heavy asterisk',
    replacement: '*'
  },
  {
    char: '\u2732',
    label: 'open centre asterisk',
    replacement: '*'
  },
  {
    char: '\u2733',
    label: 'eight spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u273A',
    label: 'sixteen pointed asterisk',
    replacement: '*'
  },
  {
    char: '\u273B',
    label: 'teardrop-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u273C',
    label: 'open centre teardrop-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u273D',
    label: 'heavy teardrop-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u2743',
    label: 'heavy teardrop-spoked pinwheel asterisk',
    replacement: '*'
  },
  {
    char: '\u2749',
    label: 'balloon-spoked asterisk',
    replacement: '*'
  },
  {
    char: '\u274A',
    label: 'eight teardrop-spoked propeller asterisk',
    replacement: '*'
  },
  {
    char: '\u274B',
    label: 'heavy eight teardrop-spoked propeller asterisk',
    replacement: '*'
  },
  {
    char: '\u29C6',
    label: 'squared asterisk',
    replacement: '*'
  },
  {
    char: '\uFE61',
    label: 'small asterisk',
    replacement: '*'
  },
  {
    char: '\uFF0A',
    label: 'fullwidth asterisk',
    replacement: '*'
  },
  {
    char: '\u02D6',
    label: 'modifier letter plus sign',
    replacement: '+'
  },
  {
    char: '\uFE62',
    label: 'small plus sign',
    replacement: '+'
  },
  {
    char: '\uFF0B',
    label: 'fullwidth plus sign',
    replacement: '+'
  },
  {
    char: '\u3002',
    label: 'ideographic full stop',
    replacement: '.'
  },
  {
    char: '\uFE52',
    label: 'small full stop',
    replacement: '.'
  },
  {
    char: '\uFF0E',
    label: 'fullwidth full stop',
    replacement: '.'
  },
  {
    char: '\uFF61',
    label: 'halfwidth ideographic full stop',
    replacement: '.'
  },
  {
    char: '\uFF10',
    label: 'fullwidth digit zero',
    replacement: '0'
  },
  {
    char: '\uFF11',
    label: 'fullwidth digit one',
    replacement: '1'
  },
  {
    char: '\uFF12',
    label: 'fullwidth digit two',
    replacement: '2'
  },
  {
    char: '\uFF13',
    label: 'fullwidth digit three',
    replacement: '3'
  },
  {
    char: '\uFF14',
    label: 'fullwidth digit four',
    replacement: '4'
  },
  {
    char: '\uFF15',
    label: 'fullwidth digit five',
    replacement: '5'
  },
  {
    char: '\uFF16',
    label: 'fullwidth digit six',
    replacement: '6'
  },
  {
    char: '\uFF17',
    label: 'fullwidth digit seven',
    replacement: '7'
  },
  {
    char: '\uFF18',
    label: 'fullwidth digit eight',
    replacement: '8'
  },
  {
    char: '\uFF19',
    label: 'fullwidth digit nine',
    replacement: '9'
  },
  {
    char: '\u02D0',
    label: 'modifier letter triangular colon',
    replacement: ': '
  },
  {
    char: '\u02F8',
    label: 'modifier letter raised colon',
    replacement: ': '
  },
  {
    char: '\u2982',
    label: 'z notation type colon',
    replacement: ': '
  },
  {
    char: '\uA789',
    label: 'modifier letter colon',
    replacement: ': '
  },
  {
    char: '\uFE13',
    label: 'presentation form for vertical colon',
    replacement: ': '
  },
  {
    char: '\uFF1A',
    label: 'fullwidth colon',
    replacement: ': '
  },
  {
    char: '\u204F',
    label: 'reversed semicolon',
    replacement: ';'
  },
  {
    char: '\uFE14',
    label: 'presentation form for vertical semicolon',
    replacement: ';'
  },
  {
    char: '\uFE54',
    label: 'small semicolon',
    replacement: ';'
  },
  {
    char: '\uFF1B',
    label: 'fullwidth semicolon',
    replacement: ';'
  },
  {
    char: '\uFE64',
    label: 'small less-than sign',
    replacement: '<'
  },
  {
    char: '\uFF1C',
    label: 'fullwidth less-than sign',
    replacement: '<'
  },
  {
    char: '\u0347',
    label: 'combining equals sign below',
    replacement: '='
  },
  {
    char: '\uA78A',
    label: 'modifier letter short equals sign',
    replacement: '='
  },
  {
    char: '\uFE66',
    label: 'small equals sign',
    replacement: '='
  },
  {
    char: '\uFF1D',
    label: 'fullwidth equals sign',
    replacement: '='
  },
  {
    char: '\uFE65',
    label: 'small greater-than sign',
    replacement: '>'
  },
  {
    char: '\uFF1E',
    label: 'fullwidth greater-than sign',
    replacement: '>'
  },
  {
    char: '\uFE16',
    label: 'presentation form for vertical question mark',
    replacement: '?'
  },
  {
    char: '\uFE56',
    label: 'small question mark',
    replacement: '?'
  },
  {
    char: '\uFF1F',
    label: 'fullwidth question mark',
    replacement: '?'
  },
  {
    char: '\uFF21',
    label: 'fullwidth latin capital letter a',
    replacement: 'A'
  },
  {
    char: '\u1D00',
    label: 'latin letter small capital a',
    replacement: 'A'
  },
  {
    char: '\uFF22',
    label: 'fullwidth latin capital letter b',
    replacement: 'B'
  },
  {
    char: '\u0299',
    label: 'latin letter small capital b',
    replacement: 'B'
  },
  {
    char: '\uFF23',
    label: 'fullwidth latin capital letter c',
    replacement: 'C'
  },
  {
    char: '\u1D04',
    label: 'latin letter small capital c',
    replacement: 'C'
  },
  {
    char: '\uFF24',
    label: 'fullwidth latin capital letter d',
    replacement: 'D'
  },
  {
    char: '\u1D05',
    label: 'latin letter small capital d',
    replacement: 'D'
  },
  {
    char: '\uFF25',
    label: 'fullwidth latin capital letter e',
    replacement: 'E'
  },
  {
    char: '\u1D07',
    label: 'latin letter small capital e',
    replacement: 'E'
  },
  {
    char: '\uFF26',
    label: 'fullwidth latin capital letter f',
    replacement: 'F'
  },
  {
    char: '\uA730',
    label: 'latin letter small capital f',
    replacement: 'F'
  },
  {
    char: '\uFF27',
    label: 'fullwidth latin capital letter g',
    replacement: 'G'
  },
  {
    char: '\u0262',
    label: 'latin letter small capital g',
    replacement: 'G'
  },
  {
    char: '\uFF28',
    label: 'fullwidth latin capital letter h',
    replacement: 'H'
  },
  {
    char: '\u029C',
    label: 'latin letter small capital h',
    replacement: 'H'
  },
  {
    char: '\uFF29',
    label: 'fullwidth latin capital letter i',
    replacement: 'I'
  },
  {
    char: '\u026A',
    label: 'latin letter small capital i',
    replacement: 'I'
  },
  {
    char: '\uFF2A',
    label: 'fullwidth latin capital letter j',
    replacement: 'J'
  },
  {
    char: '\u1D0A',
    label: 'latin letter small capital j',
    replacement: 'J'
  },
  {
    char: '\uFF2B',
    label: 'fullwidth latin capital letter k',
    replacement: 'K'
  },
  {
    char: '\u1D0B',
    label: 'latin letter small capital k',
    replacement: 'K'
  },
  {
    char: '\uFF2C',
    label: 'fullwidth latin capital letter l',
    replacement: 'L'
  },
  {
    char: '\u029F',
    label: 'latin letter small capital l',
    replacement: 'L'
  },
  {
    char: '\uFF2D',
    label: 'fullwidth latin capital letter m',
    replacement: 'M'
  },
  {
    char: '\u1D0D',
    label: 'latin letter small capital m',
    replacement: 'M'
  },
  {
    char: '\uFF2E',
    label: 'fullwidth latin capital letter n',
    replacement: 'N'
  },
  {
    char: '\u0274',
    label: 'latin letter small capital n',
    replacement: 'N'
  },
  {
    char: '\uFF2F',
    label: 'fullwidth latin capital letter o',
    replacement: 'O'
  },
  {
    char: '\u1D0F',
    label: 'latin letter small capital o',
    replacement: 'O'
  },
  {
    char: '\uFF30',
    label: 'fullwidth latin capital letter p',
    replacement: 'P'
  },
  {
    char: '\u1D18',
    label: 'latin letter small capital p',
    replacement: 'P'
  },
  {
    char: '\uFF31',
    label: 'fullwidth latin capital letter q',
    replacement: 'Q'
  },
  {
    char: '\uFF32',
    label: 'fullwidth latin capital letter r',
    replacement: 'R'
  },
  {
    char: '\u0280',
    label: 'latin letter small capital r',
    replacement: 'R'
  },
  {
    char: '\uFF33',
    label: 'fullwidth latin capital letter s',
    replacement: 'S'
  },
  {
    char: '\uA731',
    label: 'latin letter small capital s',
    replacement: 'S'
  },
  {
    char: '\uFF34',
    label: 'fullwidth latin capital letter t',
    replacement: 'T'
  },
  {
    char: '\u1D1B',
    label: 'latin letter small capital t',
    replacement: 'T'
  },
  {
    char: '\uFF35',
    label: 'fullwidth latin capital letter u',
    replacement: 'U'
  },
  {
    char: '\u1D1C',
    label: 'latin letter small capital u',
    replacement: 'U'
  },
  {
    char: '\uFF36',
    label: 'fullwidth latin capital letter v',
    replacement: 'V'
  },
  {
    char: '\u1D20',
    label: 'latin letter small capital v',
    replacement: 'V'
  },
  {
    char: '\uFF37',
    label: 'fullwidth latin capital letter w',
    replacement: 'W'
  },
  {
    char: '\u1D21',
    label: 'latin letter small capital w',
    replacement: 'W'
  },
  {
    char: '\uFF38',
    label: 'fullwidth latin capital letter x',
    replacement: 'X'
  },
  {
    char: '\uFF39',
    label: 'fullwidth latin capital letter y',
    replacement: 'Y'
  },
  {
    char: '\u028F',
    label: 'latin letter small capital y',
    replacement: 'Y'
  },
  {
    char: '\uFF3A',
    label: 'fullwidth latin capital letter z',
    replacement: 'Z'
  },
  {
    char: '\u1D22',
    label: 'latin letter small capital z',
    replacement: 'Z'
  },
  {
    char: '\u02C6',
    label: 'modifier letter circumflex accent',
    replacement: '^'
  },
  {
    char: '\u0302',
    label: 'combining circumflex accent',
    replacement: '^'
  },
  {
    char: '\uFF3E',
    label: 'fullwidth circumflex accent',
    replacement: '^'
  },
  {
    char: '\u1DCD',
    label: 'combining double circumflex above',
    replacement: '^'
  },
  {
    char: '\u2774',
    label: 'medium left curly bracket ornament',
    replacement: ''
  },
  {
    char: '\uFE5B',
    label: 'small left curly bracket',
    replacement: ''
  },
  {
    char: '\uFF5B',
    label: 'fullwidth left curly bracket',
    replacement: ''
  },
  {
    char: '\u2775',
    label: 'medium right curly bracket ornament',
    replacement: '}'
  },
  {
    char: '\uFE5C',
    label: 'small right curly bracket',
    replacement: '}'
  },
  {
    char: '\uFF5D',
    label: 'fullwidth right curly bracket',
    replacement: '}'
  },
  {
    char: '\uFF3B',
    label: 'fullwidth left square bracket',
    replacement: ''
  },
  {
    char: '\uFF3D',
    label: 'fullwidth right square bracket',
    replacement: ']'
  },
  {
    char: '\u02DC',
    label: 'small tilde',
    replacement: '~'
  },
  {
    char: '\u02F7',
    label: 'modifier letter low tilde',
    replacement: '~'
  },
  {
    char: '\u0303',
    label: 'combining tilde',
    replacement: '~'
  },
  {
    char: '\u0330',
    label: 'combining tilde below',
    replacement: '~'
  },
  {
    char: '\u0334',
    label: 'combining tilde overlay',
    replacement: '~'
  },
  {
    char: '\u223C',
    label: 'tilde operator',
    replacement: '~'
  },
  {
    char: '\uFF5E',
    label: 'fullwidth tilde',
    replacement: '~'
  },
  {
    char: '\u00A0',
    label: 'no-break space',
    replacement: ''
  },
  {
    char: '\u2000',
    label: 'whitespace: en quad',
    replacement: ''
  },
  {
    char: '\u2001',
    label: 'whitespace: medium mathematical space',
    replacement: ' '
  },
  {
    char: '\u2002',
    label: 'whitespace: en space',
    replacement: ''
  },
  {
    char: '\u2003',
    label: 'whitespace: em space',
    replacement: ''
  },
  {
    char: '\u2004',
    label: 'whitespace: three-per-em space',
    replacement: ''
  },
  {
    char: '\u2005',
    label: 'whitespace: four-per-em space',
    replacement: ''
  },
  {
    char: '\u2006',
    label: 'whitespace: six-per-em space',
    replacement: ''
  },
  {
    char: '\u2007',
    label: 'whitespace: figure space',
    replacement: ''
  },
  {
    char: '\u2008',
    label: 'whitespace: punctuation space',
    replacement: ''
  },
  {
    char: '\u2009',
    label: 'whitespace: thin space',
    replacement: ''
  },
  {
    char: '\u200A',
    label: 'whitespace: hair space',
    replacement: ''
  },
  {
    char: '\u202F',
    label: 'narrow no-break space',
    replacement: ''
  },
  {
    char: '\u205F',
    label: 'medium mathematical space',
    replacement: ''
  },
  {
    char: '\u3000',
    label: 'ideograhpic space',
    replacement: ''
  },
  {
    char: '\u008D',
    label: 'reverse line feed',
    replacement: ''
  },
  {
    char: '\u009F',
    label: 'control',
    replacement: ''
  },
  {
    char: '\u0080',
    label: 'c1 control codes',
    replacement: ''
  },
  {
    char: '\u0090',
    label: 'device control string',
    replacement: ''
  },
  {
    char: '\u009B',
    label: 'control sequence introducer',
    replacement: ''
  },
  {
    char: '\u0010',
    label: 'escape',
    replacement: ''
  },
  {
    char: '\u0009',
    label: 'tab (7 spaces based on print statement in python interpreter)',
    replacement: ''
  },
  {
    char: '\u0000',
    label: 'null',
    replacement: ''
  },
  {
    char: '\u0003',
    label: 'end of text',
    replacement: ''
  },
  {
    char: '\u0004',
    label: 'end of transmission',
    replacement: ''
  },
  {
    char: '\u0017',
    label: 'end of transmission block',
    replacement: ''
  },
  {
    char: '\u0019',
    label: 'end of medium',
    replacement: ''
  },
  {
    char: '\u0011',
    label: 'device control one',
    replacement: ''
  },
  {
    char: '\u0012',
    label: 'device control two',
    replacement: ''
  },
  {
    char: '\u0013',
    label: 'device control three',
    replacement: ''
  },
  {
    char: '\u0014',
    label: 'device control four',
    replacement: ''
  },
  {
    char: '\u2017',
    label: 'double low line',
    replacement: '_'
  },
  {
    char: '\u2014',
    label: 'em dash',
    replacement: '-'
  },
  {
    char: '\u2013',
    label: 'en dash',
    replacement: '-'
  },
  {
    char: '\u201A',
    label: 'single low quotation mark',
    replacement: '\''
  },
  {
    char: '\u202F',
    label: 'narrow break space',
    replacement: ' '
  },
  {
    char: '\u2039',
    label: 'single left-pointing angle quotation mark',
    replacement: '>'
  },
  {
    char: '\u203A',
    label: 'single right-pointing angle quotation mark',
    replacement: '<'
  },
  {
    char: '\u203C',
    label: 'double exclamation mark',
    replacement: '!!'
  },
  {
    char: '\u201E',
    label: 'double low quotation mark',
    replacement: ''
  },
  {
    char: '\u201D',
    label: 'right double quotation mark',
    replacement: ''
  },
  {
    char: '\u201C',
    label: 'left double quotation mark',
    replacement: '"'
  },
  {
    char: '\u201B',
    label: 'single high reversed quotation mark',
    replacement: '\''
  },
  {
    char: '\u2026',
    label: ' horizontal ellipsis',
    replacement: '...'
  },
  {
    char: '\u2028',
    label: 'whitespace: line separator',
    replacement: ' '
  },
  {
    char: '\u2029',
    label: 'whitespace: paragraph separator',
    replacement: ' '
  },
  {
    char: '\u205F',
    label: 'whitespace: em quad',
    replacement: ' '
  },
  {
    char: '\u2060',
    label: 'word joiner',
    replacement: ' '
  }
];

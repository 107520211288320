import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import accounts from './accounts';
import call from './call';
import conversations from './conversations';
import messages from './messages';
import user from './user';

const reducers = (history) => combineReducers({
  router: connectRouter(history),
  accounts,
  call,
  conversations,
  messages,
  user,
});

export default reducers;

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class BottomAnchoredViewport extends Component {

  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.fromBottom = 0;
  }

  componentDidMount() {
    const {height, onCloseToTop} = this.props;
    this.element.current.onscroll = ({target: {scrollTop, scrollHeight, offsetHeight}}) => {
      this.fromBottom = scrollHeight - scrollTop - offsetHeight;
      if (onCloseToTop && scrollTop < height) {
        if (this.debounceCloseToTop) {
          clearTimeout(this.debounceCloseToTop);
        }
        this.debounceCloseToTop = setTimeout(() => {
          onCloseToTop();
        }, 250);
      }
    };
  }

  componentDidUpdate() {
    const {scrollHeight, offsetHeight} = this.element.current;
    this.element.current.scrollTop = scrollHeight - this.fromBottom - offsetHeight;
  }

  render() {
    return (
      <div
        style={{
          height: this.props.height,
          marginRight: -7,
          marginLeft: -7,
          overflow: 'hidden',
        }}
      >
        <div
          ref={this.element}
          style={{
            height: this.props.height,
            width: '100%',
            overflowY: 'scroll',
            paddingRight: 17,
            boxSizing: 'content-box',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

BottomAnchoredViewport.propTypes = {
  height: PropTypes.number.isRequired,
  onCloseToTop: PropTypes.func,
  children: PropTypes.element,
};

export {BottomAnchoredViewport};

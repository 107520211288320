import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import PageWrapper from './pages/PageWrapper';
import {Login} from './components/Login';
import AccountPage from './pages/AccountPage';

const App = ({loggedIn}) => (
  <PageWrapper>
    {
      !loggedIn ? <Login /> :
        <Switch>
          <Route path="/a" component={AccountPage} />
          <Redirect to="/a" />
        </Switch>
    }
  </PageWrapper>
);

export default connect(state => ({
  loggedIn: !!state.user.user,
}))(App);

import {UPDATE_ACCOUNT} from '../reducers/accounts';
import {UPDATE_CONVERSATION} from '../reducers/conversations';
import {UPDATE_MESSAGE} from '../reducers/messages';
import {toArrayWithIds} from '../firebase/utils';
import {alert} from '../../assets/sounds/alert';

let referenceTimestamp = +new Date();

const notificationMiddleware = ({dispatch, getState}) => next => action => {
  let message;
  try {
    switch (action.type) {
    case UPDATE_MESSAGE:
      message = toArrayWithIds(action.payload.message)[0];
      if (message && message.unseen && message.timestamp > referenceTimestamp) {
        alert.playChime();
        new Notification(message.content || 'New Message');
        referenceTimestamp = message.timestamp;
      }
      break;
    case UPDATE_CONVERSATION:
      message = toArrayWithIds(action.payload.message)[0];
      if (message && message.unseenCount > 0 && message.timestamp > referenceTimestamp) {
        alert.playChime();
        new Notification(message.label || message.id, {body: message.preview});
        referenceTimestamp = message.timestamp;
      }
      break;
    case UPDATE_ACCOUNT:
      let {payload: {account}} = action;
      account = account && toArrayWithIds(account)[0];
      if (account && account.unseenCount > 0 && account.unseenTimestamp > referenceTimestamp) {
        alert.playChime();
        new Notification(account.label || account.id, {body: 'New Message for account'});
        referenceTimestamp = account.unseenTimestamp;
      }
      break;
    default:
    }
  } catch (e) {
    console.error(e.message);
  }
  return next(action);
};

export {
  notificationMiddleware,
};

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
 ArrowBottomRightIcon,
 ArrowTopRightIcon,
 Card,
 CleanIcon,
 Icon,
 Pane,
 Strong,
 Text,
 ThemeConsumer,
 Tooltip
} from 'evergreen-ui';
import moment from 'moment';

import {formatSeconds} from '../lib/utils';
import {AudioPlayer} from './AudioPlayer';

const Voicemail = ({direction, content, recordingUrl, unseen, duration, status, timestamp}) => (
  <ThemeConsumer>
    { theme => (
      <Pane margin={8} paddingBottom={8}>
        <Pane display="flex" justifyContent="flex-start">
          <Card
            background="blueTint"
            borderRadius={8}
            padding={8}
            marginBottom={8}
            display="flex"
            alignItems="center"
            style={{maxWidth: '75%'}}
          >
            { recordingUrl &&
              <Pane>
                <AudioPlayer src={recordingUrl} />
              </Pane>
            }
            { content ?
              <Strong marginLeft={16} flex={1} color="muted">
                {content}
              </Strong>
              :
              <Text marginLeft={16} flex={1} color="muted">
                <em>Voicemail not transcribed.</em>
              </Text>
            }
          </Card>
          { unseen && <Icon icon={CleanIcon} margin={8} color={theme.palette.yellow.base} /> }
        </Pane>
      </Pane>
    )}
  </ThemeConsumer>
);

const Call = ({direction, unseen, duration, status, timestamp}) => (
  <ThemeConsumer>
    { theme => (
      <Pane margin={8} paddingBottom={8}>
        <Pane display="flex" justifyContent="center">
          { direction === 'inbound' ?
            <Icon icon={ArrowBottomRightIcon} size={24} margin={8} color={theme.palette.green.base} />
            :
            <Icon icon={ArrowTopRightIcon} size={24} margin={8} color={theme.palette.green.base} />
          }
          <Text color={theme.palette.neutral.base}>
            Call Duration: {formatSeconds(duration)}
            <br/>
            Call Status: {status}
          </Text>
          { unseen && <Icon icon={CleanIcon} margin={8} color={theme.palette.yellow.base} /> }
        </Pane>
        <Pane paddingX={8} display="flex" justifyContent="center">
          <Tooltip content={moment(timestamp).format('dd, MMM D, YYYY @ h:mm a')} showDelay={1000}>
            <Text color={theme.scales.neutral.N6}>
              {moment(timestamp).fromNow()}
            </Text>
          </Tooltip>
        </Pane>
      </Pane>
    )}
  </ThemeConsumer>
);

const TextMessage = ({direction, content, media, unseen, timestamp}) => (
  <ThemeConsumer>
    { theme => (
      <Pane margin={8} paddingBottom={8}>
        { media && media.map(({url, contentType}) => (
          <Pane key={url} display="flex" justifyContent={direction === 'inbound' ? 'flex-start' : 'flex-end'}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Card background={direction === 'inbound' ? 'yellowTint' : 'tint2'} borderRadius={8} padding={8} marginBottom={8}>
                {contentType && contentType.match(/^image\//) &&
                  <img src={url} height={250} alt="MMS" />
                }
                {contentType && contentType.match(/^video\//) &&
                  <video height={250} controls>
                    <source src={url} type={contentType} />
                  </video>
                }
              </Card>
            </a>
          </Pane>
        ))}
        { content &&
          <Pane display="flex" justifyContent={direction === 'inbound' ? 'flex-start' : 'flex-end'}>
            <Card background={direction === 'inbound' ? 'yellowTint' : 'tint2'} borderRadius={8} padding={8} marginBottom={8} style={{maxWidth: '75%'}}>
              {
                content.split(/\n/).map((line, idx) => (
                  <Text key={idx} color={theme.palette.neutral.base} fontWeight={unseen ? 700 : 400}>
                    {line}<br/>
                  </Text>
                ))
              }
            </Card>
            { unseen && <Icon icon={CleanIcon} margin={8} color={theme.palette.yellow.base} /> }
          </Pane>
        }
        <Pane paddingX={8} display="flex" justifyContent={direction === 'inbound' ? 'flex-start' : 'flex-end'}>
          <Tooltip content={moment(timestamp).format('dd, MMM D, YYYY @ h:mm a')} showDelay={1000}>
            <Text color={theme.scales.neutral.N6}>
              {moment(timestamp).fromNow()}
            </Text>
          </Tooltip>
        </Pane>
      </Pane>
    )}
  </ThemeConsumer>
);

const Message = (props) => {
  switch (props.type) {
  case 'call':
    return <Call {...props} />;

  case 'voicemail':
    return <Voicemail {...props} />;

  case 'message':
    return <TextMessage {...props} />;

  default:
    return <pre>Unknown message type: {JSON.stringify(props)}</pre>;
  }
};

const Messages = ({messages}) => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const ticker = setInterval(() => setDate(new Date()), 60 * 1000);
    return () => clearInterval(ticker);
  });

  return (
    <div className="Messages">
      {
        Object.keys(messages)
          .map(id => Object.assign({id}, messages[id]))
          .sort((a,b) => a.timestamp - b.timestamp)
          .map(({id, ...message}) => (
            <Message key={id} {...message} />
          ))
      }
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.object.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
};

Messages.defaultProps = {
  messages: {},
};

export {Messages};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {startUserAuthListener} from '../lib/reducers/user';

class PageWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      applicationReady: true,
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(startUserAuthListener());
  }

  render() {
    if (!this.state.applicationReady) {
      return null;
    }
    return (
      <div className="PageWrapper">
        {this.props.children}
      </div>
    );
  }

}

PageWrapper.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func.isRequired,
};

export default connect(({user: {user}, accounts}) => ({
  user: user,
  accounts,
}))(PageWrapper);

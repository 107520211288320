import {firebase} from '../firebase';

const LOGIN_USER = 'schmetterling/users/LOGIN_USER';
const LOGOUT_USER = 'schmetterling/users/LOGOUT_USER';

const initialState = {
  user: null,
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
  case LOGIN_USER:
    return {user: payload.user};
  case LOGOUT_USER:
    return initialState;
  default:
    return state;
  }
};

const login = user => ({
  type: LOGIN_USER,
  payload: {user},
});

const logout = () => ({
  type: LOGOUT_USER,
});

export const logoutUser = () => {
  firebase.auth().signOut();
};

export const startUserAuthListener = () => (dispatch) => {
  firebase.auth().useDeviceLanguage();
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch(login(user));
    } else {
      dispatch(logout(user));
    }
  });
};

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {
  Heading,
  Button,
  TextInput,
  Tab,
  Pane,
  Pill
} from 'evergreen-ui';

import {placeCall} from '../lib/reducers/call';
import {firebase} from '../lib/firebase';

class InternalHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: null,
      displayPhoneNumber: '',
    };
  }

  render() {
    const {dispatch, accounts, match: {params}} = this.props;

    return (
      <header>
        <Pane display="flex">
          <Heading size={600} padding={8} flex={1}>
            <span role="img" aria-label="butterfly">🦋</span>
          </Heading>
          {
            params.accountPhoneNumber &&
            <Pane>
              <TextInput
                width={120}
                marginRight={8}
                placeholder="+1 555 111 5555"
                value={this.state.displayPhoneNumber}
                onChange={e => {
                  let p = e.target.value.replace(/\D+/g, '');
                  let phoneNumber = null;
                  if (p.length > 0) {
                    p = `+${p.charAt(0) === '1' ? '' : '1'}${p}`;
                    const matches = /(\+1)?(\d{3})?(\d{3})?(\d+)?/.exec(p);
                    phoneNumber = matches[0].length === 12 ? p : null;
                    p = matches.slice(1).filter(v => typeof v !== 'undefined').join(' ');
                  }
                  this.setState({phoneNumber, displayPhoneNumber: p});
                }}
              />

              <Button
                marginRight={8}
                iconBefore="phone"
                disabled={!this.state.phoneNumber}
                onClick={() => {
                  dispatch(placeCall(params.accountPhoneNumber, this.state.phoneNumber));
                }}
              >
                Call
              </Button>
              <Button
                marginRight={32}
                iconBefore="chat"
                disabled={!this.state.phoneNumber}
                onClick={() => {
                  dispatch(push(`/a/${params.accountPhoneNumber}/${this.state.phoneNumber}/`));
                }}
              >
                Text
              </Button>

              <Button onClick={ () => window.confirm('Are you sure?') && firebase.auth().signOut() }>Logout</Button>
            </Pane>
          }
        </Pane>

        <Pane display="flex" marginY={16} style={{width: '100%', overflowX: 'auto', overflow: 'none', height: 45 }}>
          <Pane flex={1} display="flex">
            {
              Object.keys(accounts).map(accountPhoneNumber => {
                const {label, unseenCount} = accounts[accountPhoneNumber];
                return (
                  <Tab
                    key={accountPhoneNumber}
                    is={Link}
                    to={`/a/${accountPhoneNumber}`}
                    isSelected={params.accountPhoneNumber === accountPhoneNumber}
                    style={{whiteSpace: 'nowrap'}}
                  >
                    {label || accountPhoneNumber}
                    {unseenCount > 0 &&
                      <Pill display="inline-flex" margin={8} color="red" isSolid>
                        {unseenCount}
                      </Pill>
                    }
                  </Tab>
                );
              })
            }
          </Pane>
        </Pane>
      </header>
    );
  }
}

InternalHeader.propTypes = {
  accounts: PropTypes.object.isRequired,
};

InternalHeader.defaultProps = {
  accounts: {},
};

const Header = withRouter(connect(({accounts: {accounts}})=>({accounts}))(InternalHeader));

export {
  Header,
};

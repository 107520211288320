import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';

import {
  startAccountListener,
  stopAccountListener,
  resetAccounts
} from '../lib/reducers/accounts';
import {AccountsPanel} from '../components/AccountsPanel';
import {ConversationsPanel} from '../components/ConversationsPanel';
import ConversationPanel from '../components/ConversationPanel';

class AccountPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this._startAccountListener = this._startAccountListener.bind(this);
    this._stopAccountListener = this._stopAccountListener.bind(this);
    this._resetAccounts = this._resetAccounts.bind(this);
  }

  componentDidMount() {
    this._startAccountListener();
  }

  componentDidUpdate(prevProps) {
    const {user: {uid: prevUid}} = prevProps;
    const {user: {uid}} = this.props;
    if (prevUid !== uid) {
      this._resetAccounts();
      this._startAccountListener();
    }
  }

  componentWillUnmount() {
    this._resetAccounts();
    this._startAccountListener();
  }

  _startAccountListener() {
    const {dispatch} = this.props;
    dispatch(startAccountListener());
  }

  _stopAccountListener() {
    const {dispatch} = this.props;
    dispatch(stopAccountListener());
  }

  _resetAccounts() {
    const {dispatch} = this.props;
    dispatch(resetAccounts());
  }

  render() {
    const {match: {path}} = this.props;

    return (
      <div className="AccountPage">
        <Route exact path={`${path}/`} component={AccountsPanel} />
        <Route exact path={`${path}/:accountPhoneNumber`} component={ConversationsPanel} />
        <Route exact path={`${path}/:accountPhoneNumber/:phoneNumber`} component={ConversationPanel} />
      </div>
    );
  }

}

AccountPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  user: state.user.user,
}))(AccountPage);

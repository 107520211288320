import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  fetchConversations,
  resetConversations,
  startConversationListener,
  stopConversationListener
} from '../lib/reducers/conversations';
import {List} from '../components/List';
import {Header} from '../components/Header';

class InternalConversationsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this._loadMoreConversations = this._loadMoreConversations.bind(this);
    this._resetConversations = this._resetConversations.bind(this);
    this._startConversationListener = this._startConversationListener.bind(this);
    this._stopConversationListener = this._stopConversationListener.bind(this);
    this._handleUpdateContact = this._handleUpdateContact.bind(this);
  }

  componentDidMount() {
    this._startConversationListener();
  }

  componentWillUnmount() {
    this._stopConversationListener();
    this._resetConversations();
  }

  componentDidUpdate(prevProps) {
    const {accountPhoneNumber: prevAccountPhoneNumber} = prevProps;
    const {accountPhoneNumber} = this.props;
    if (accountPhoneNumber !== prevAccountPhoneNumber) {
      this._resetConversations();
      this._startConversationListener();
    }
  }

  _loadMoreConversations() {
    const {dispatch, accountPhoneNumber} = this.props;
    dispatch(fetchConversations(accountPhoneNumber, 200));
  }

  _startConversationListener() {
    const {dispatch, accountPhoneNumber} = this.props;
    dispatch(startConversationListener(accountPhoneNumber, 25));
  }

  _stopConversationListener() {
    const {dispatch} = this.props;
    dispatch(stopConversationListener());
  }

  _resetConversations() {
    const {dispatch} = this.props;
    dispatch(resetConversations());
  }

  async _handleUpdateContact(phoneNumber, name, doneCallback = null) {
    const idToken = await this.props.user.getIdToken();
    const body = {
      phoneNumber,
      name,
    };
    return fetch('https://us-central1-cs-cms.cloudfunctions.net/apiUpdateContact/', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        }),
      })
      .catch(e => console.error)
      .then(() => doneCallback && doneCallback());
  }

  render() {
    return (
      <div className="ConversationsPanel">
        <Header />
        <List
          compactMode={window.innerWidth < 500}
          baseUrl={this.props.url}
          accountPhoneNumber={this.props.accountPhoneNumber}
          conversations={this.props.conversations}
          onLoadMoreClick={this._loadMoreConversations}
          onUpdateContact={this._handleUpdateContact}
        />
      </div>
    );
  }

}

InternalConversationsPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({user: {user}, conversations: {conversations}}, {match: {url, params: {accountPhoneNumber}}}) => ({
  conversations,
  user,
  accountPhoneNumber,
  url,
});

export const ConversationsPanel = withRouter(connect(mapStateToProps)(InternalConversationsPanel));

import React from 'react';
import styled from 'styled-components';
import {toaster, Button, Card, Pane, TextInputField, Spinner} from 'evergreen-ui';

import {firebase} from '../lib/firebase';

const Title = styled.h1`
  font-family: Product Sans;
  font-size: 3em;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: null,
      codeSent: false,
      loggedIn: false,
      code: '',
    };
    this._handleLogin = this._handleLogin.bind(this);
    this._handleCodeEntered = this._handleCodeEntered.bind(this);
    this._handleLogout = this._handleLogout.bind(this);
    this._handlePhoneNumberChange = this._handlePhoneNumberChange.bind(this);
    this._isPhoneNumberValid = this._isPhoneNumberValid.bind(this);
    this._handleCodeChange = this._handleCodeChange.bind(this);
    this._initializeRecaptcha = this._initializeRecaptcha.bind(this);
  }

  componentDidMount() {
    this._initializeRecaptcha();
  }

  componentDidUpdate(prepProp, prevState) {
    if (prevState.loggedIn && !this.state.loggedIn) {
      this._initializeRecaptcha();
    }
  }

  render() {
    const {
      codeSent, phoneNumber, code, loading /*, loggedIn, users*/
    } = this.state;
    return (
      <Pane
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={40}
      >
        <Card
          elevation={1}
          height={400}
          width={400}
          border="default"
          padding={40}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Pane
            flex={1}
            display="flex"
            alignItems="center"
          >
            <Title>Login</Title>
          </Pane>
          {
            !codeSent &&
              <Pane
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >

                {
                  loading ?
                    <Spinner
                      flex={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    />
                    :
                    <Pane flex={1} />
                }
                <Pane
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <TextInputField
                    onChange={this._handlePhoneNumberChange}
                    width={300}
                    value={phoneNumber || ''}
                    label="Enter your phone number"
                    placeholder="+15550005555"
                  />
                  <Button
                    id="send-code"
                    type="submit"
                    appearance="primary"
                    height={48}
                    iconBefore="phone"
                    disabled={this._isPhoneNumberValid()}
                    onClick={this._handleLogin}
                  >
                    Send Code
                  </Button>
                </Pane>
              </Pane>
          }

          {
            codeSent &&
              <Pane
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Pane
                  flex={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <TextInputField
                    id="login-code"
                    onChange={this._handleCodeChange}
                    width={300}
                    value={code}
                    label="Enter the code from the text message:"
                    placeholder="000 000"
                  />
                  <Button
                    disabled={code.length !== 6}
                    appearance="primary"
                    height={48}
                    iconBefore="user"
                    onClick={this._handleCodeEntered}
                  >
                    Sign In
                  </Button>
                </Pane>

              </Pane>

          }
        </Card>
      </Pane>
    );
  }

  _initializeRecaptcha() {
    this._recaptchaVerifier = new firebase.auth.RecaptchaVerifier('send-code', {
      size: 'invisible',
      callback: () => this.setState({codeSent: true}),
    });
  }

  _isPhoneNumberValid() {
    const {phoneNumber} = this.state;
    return !(phoneNumber && /^\+1\d{10}$/.test(phoneNumber));
  }

  _handlePhoneNumberChange(e) {
    let {target: {value}} = e;
    value = value.split(/[^+\d]+/).join('');
    this.setState({phoneNumber: value});
  }

  _handleCodeChange(e) {
    const {target: {value}} = e;
    this.setState({code: value.split(/[^\d]+/).join('').slice(0, 6)});
  }

  async _handleLogin(e) {
    e.preventDefault();

    if (this.state.loading) {
      return;
    }
    this.setState({loading: true});

    const {phoneNumber} = this.state;

    const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, this._recaptchaVerifier);
    this._confirmationResult = confirmationResult;
  }

  _handleLogout(e) {
    e.preventDefault();

    firebase.auth().signOut()
      .then(() => {})
      .catch(error => this._errorAlert(error.message));
  }

  _handleCodeEntered(e) {
    e.preventDefault();

    // this.setState({loading: true});

    this._confirmationResult.confirm(this.state.code)
      .then((result) => {
        this.setState({
          user: result.user, loggedIn: true, codeSent: false, code: '',
        });
      })
      .catch(error => this._errorAlert('The code you entered is invalid.'));
    // .then(() => this.setState({loading: false}));
  }

  _errorAlert(msg) {
    toaster.danger(msg);
  }
}

export {Login};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IconButton, PauseIcon, PlayIcon} from 'evergreen-ui';

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
    this.audio = React.createRef();
    this._handleButtonClicked = this._handleButtonClicked.bind(this);
    this._handleAudioEnded = this._handleAudioEnded.bind(this);
  }

  componentDidMount() {
    this.audio.current.addEventListener('ended', this._handleAudioEnded);
  }

  componentWillUnmount() {
    this.audio.current.removeEventListener('ended', this._handleAudioEnded);
  }

  _handleAudioEnded() {
    this.setState({playing: false});
  }

  _handleButtonClicked(e) {
    e.preventDefault();
    const audio = this.audio.current;
    if (audio.paused) {
      audio.play();
      this.setState({playing: true});
    } else {
      audio.pause();
      this.setState({playing: false});
    }
  }

  render() {
    const {playing} = this.state;
    return (
      <span>
        <IconButton icon={playing ? PauseIcon : PlayIcon} height={40} onClick={this._handleButtonClicked}/>
        <audio preload="none" ref={this.audio}>
          <source src={this.props.src} type="audio/mpeg"/>
            Your browser does not support the audio element.
        </audio>
      </span>
    );
  }
}

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export {
  AudioPlayer,
};

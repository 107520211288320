import React from 'react';
import {render} from 'react-dom';
import {createBrowserHistory} from 'history';
import {createStore, applyMiddleware, compose} from 'redux';
import {ConnectedRouter, routerMiddleware} from 'connected-react-router';
import {Provider} from 'react-redux';
import {Route} from 'react-router-dom';
import thunk from 'redux-thunk';

import {notificationMiddleware} from './lib/middlewares/notificationMiddleware';
import createRootReducer from './lib/reducers';
import App from './App';
import './assets/css/index.css';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      notificationMiddleware,
      thunk,
    )
  ),
);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'));

import {firebase} from '../firebase';

export const FETCH_REQUEST = 'schmetterling/messages/FETCH_REQUEST';
export const FETCH_SUCCESS = 'schmetterling/messages/FETCH_SUCCESS';
export const FETCH_FAILURE = 'schmetterling/messages/FETCH_FAILURE';
export const UPDATE_MESSAGE = 'schmetterling/messages/UPDATE_MESSAGE';
export const REMOVE_MESSAGE = 'schmetterling/messages/REMOVE_MESSAGE';
export const RESET_MESSAGES = 'schmetterling/messages/RESET_MESSAGES';

const initialState = {
  inflight: null,
  timestampCursor: null,
  messages: {},
  error: null,
};

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {

  case FETCH_REQUEST:
    return {...state, inflight: payload.inflight, error: null};
  case FETCH_SUCCESS:
    return {...state, inflight: null, messages: payload.messages, timestampCursor: payload.timestampCursor};
  case FETCH_FAILURE:
    console.error(payload.error.message);
    return {...state, inflight: null, error: payload.error};

    // case UPDATE_MESSAGE:
    //   return {...state, messages: {...state.messages, ...payload.message}};
    // case REMOVE_MESSAGE:
    //   const {[payload.phoneNumber]: _, ...newMessages} = state.messages;
    //   return {...state, messages: newMessages};

  case UPDATE_MESSAGE:
    const {timestampCursor} = state;
    const {timestamp} = Object.values(payload.message)[0];
    return {...state, messages: {...state.messages, ...payload.message}, timestampCursor: (timestampCursor && timestampCursor < timestamp ? timestampCursor : timestamp)};
  case REMOVE_MESSAGE:
    const {[payload.phoneNumber]: _, ...newConversations} = state.messages;
    return {...state, messages: newConversations};

  case RESET_MESSAGES:
    return initialState;

  default:
    return state;
  }
};

const fetchRequest = inflight => ({
  type: FETCH_REQUEST,
  payload: {inflight},
});

const fetchSuccess = (messages, timestampCursor) => ({
  type: FETCH_SUCCESS,
  payload: {messages, timestampCursor},
});

const fetchFailure = error => ({
  type: FETCH_FAILURE,
  payload: {error},
});

export const updateMessage = message => ({
  type: UPDATE_MESSAGE,
  payload: {message},
});

export const removeMessage = id => ({
  type: REMOVE_MESSAGE,
  payload: {id},
});

export const resetMessages = () => ({
  type: RESET_MESSAGES,
});

export const fetchMessages = (accountPhoneNumber, phoneNumber, size) => (dispatch, getState) => {
  const {messages: {messages, inflight, timestampCursor}} = getState();

  if (inflight && inflight.accountPhoneNumber === accountPhoneNumber && inflight.phoneNumber === phoneNumber) {
    return;
  }

  dispatch(fetchRequest({accountPhoneNumber, phoneNumber}));

  let ref = firebase.database().ref(`/conversations/${accountPhoneNumber}/${phoneNumber}/`)
    .orderByChild('timestamp');

  if (timestampCursor) {
    ref = ref.endAt(timestampCursor, 'timestamp');
  }

  ref = ref.limitToLast(size+1);

  ref.once('value')
    .then(snap => {
      const newMessages = snap.val();
      const newTimestampCursor = Object.values(newMessages).map(n => n.timestamp).sort()[0];
      dispatch(fetchSuccess({...messages, ...newMessages}, newTimestampCursor));
    })
    .catch(error => dispatch(fetchFailure(error)));
};

let listenerRef, childAddedListener, childChangedListener, childRemovedListener;

export const startMessagesListener = (accountPhoneNumber, phoneNumber, size) => (dispatch) => {
  listenerRef = firebase.database().ref(`/conversations/${accountPhoneNumber}/${phoneNumber}/`).orderByChild('timestamp').limitToLast(size);

  childAddedListener = listenerRef.on('child_added', (childSnapshot) => {
    dispatch(updateMessage({[childSnapshot.key]: childSnapshot.val()}));
  });

  childChangedListener = listenerRef.on('child_changed', (childSnapshot) => {
    dispatch(updateMessage({[childSnapshot.key]: childSnapshot.val()}));
  });

  childRemovedListener = listenerRef.on('child_removed', (childSnapshot) => {
    dispatch(removeMessage(childSnapshot.key));
  });
};

export const stopMessagesListener = () => () => {
  if (childAddedListener) {
    listenerRef.off('child_added', childAddedListener);
    childAddedListener = null;
  }
  if (childChangedListener) {
    listenerRef.off('child_changed', childChangedListener);
    childChangedListener = null;
  }
  if (childRemovedListener) {
    listenerRef.off('child_added', childRemovedListener);
    childRemovedListener = null;
  }
  if (listenerRef) {
    listenerRef = null;
  }
};

export default reducer;
